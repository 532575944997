import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { WhatsNewComponent } from "./whats-new.component";
import { StorageService } from "../shared/services/storage.service";
import { firstValueFrom } from "rxjs";
import { appInfo } from "src/environments/appinfo";
import whatsNew from "./whats-new";

@Injectable({
  providedIn: "root",
})
export class WhatsNewService {
  constructor(
    private dialogService: DialogService,
    private storageService: StorageService,
  ) {
  }

  async processWindow() {
    const el = document.querySelector("app-navbar");
    if (!el) {
      setTimeout(() => {
        this.processWindow();
      }, 2000);
      return;
    }
    const buildNo = this.storageService.getStorage(
      this.storageService.keys.FeatureBuildNoLastKnown,
    ) || 1;
    const featureList = whatsNew.filter((x) => x.b > buildNo).length;
    if (!featureList) {
      return;
    }
    await this.open(buildNo);

    this.storageService.setStorage(
      this.storageService.keys.FeatureBuildNoLastKnown,
      appInfo.b,
    );
  }

  async open(minBuildNo = 0) {
    const ref = this.dialogService.open(WhatsNewComponent, {
      width: "85%",

      styleClass:'colored-modal',
      height: "60%",
      data: {
        build: minBuildNo,
      },
      header: "iFlows Bug fixes & New feature enhancements!!",
    });
    await firstValueFrom(ref.onClose);
    return true;
  }
}

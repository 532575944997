<div *ngIf="options" class="f-col f-fixed">
    <div *ngIf="options.renameAction" class="f-row">
        <div class="f-col f-flex">
            <label class="clickable">{{options.label}}</label>
            <div class="f-row">
                <div *ngIf="!options.textarea" class="f-flex f-col">
                    <input (keyup.enter)="save()" [maxlength]="options.maxLength|| null" (input)="result.touched=true"
                        [(ngModel)]="result.value" pInputText placeholder="Enter value" />
                </div>
                <div *ngIf="options.textarea" class="f-flex f-col">
                    <textarea  [maxlength]="options.maxLength || null" (input)="result.touched=true"
                        [(ngModel)]="result.value" [rows]="5" pInputTextarea placeholder="Enter value" ></textarea>
                </div>
            </div>
            <small class="label error-msg" *ngIf="errorMsg">{{errorMsg}}</small>
        </div>

    </div>
    <div class="f-row">
        <div *ngIf="options.deleteAction" class="f-col f-fixed">
            <p-button (click)="del()" [outlined]="true" size="small" label="Delete" severity="danger"></p-button>
        </div>
        <div class="f-col f-flex"></div>
        <div class="f-col f-fixed">
            <p-button (click)="close()" [outlined]="true" size="small" label="Cancel" severity="secondary"></p-button>
        </div>
        <div class="f-col f-fixed">
            <p-button (click)="save()" [outlined]="true" size="small"
                [disabled]="options.isRequired && !result.value || errorMsg!=''" label="Save"
                severity="primary"></p-button>
        </div>
    </div>
</div>
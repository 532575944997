import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private config = {
    keyPairPrefix: "key_",
  };

  public keys = {
    FeatureBuildNoLastKnown: 'feature_build_no_last_known',
    BuilderNodeCopy: 'builder_node_copy'
  };

  constructor() {}

  getStorage(key: string, defaultValue: any = undefined): any {
    try {
      key = this.config.keyPairPrefix + key;
      let data = localStorage.getItem(key);
      return JSON.parse(data as any);
    } catch (e) {}
    return defaultValue;
  }

  setStorage(key: string, data: any) {
    try {
      key = this.config.keyPairPrefix + key;
      data = JSON.stringify(data);
      localStorage.setItem(key, data);
      return true;
    } catch (e) {}
    return false;
  }
}

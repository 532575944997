import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ApiService } from "src/app/core/services/api.service";
import { first, firstValueFrom } from "rxjs";
import { AppDialogService } from "src/app/core/components/app-dialog/app-dialog.service";
import { AppDropdownModalOptions } from "./app-dropdown-modal.model";

@Component({
  selector: "app-dropdown-modal",
  templateUrl: "./app-dropdown-modal.component.html",
  styleUrls: ["./app-dropdown-modal.component.scss"],
})
export class AppDropdownModalComponent implements OnInit {
  loading = true;
  options: AppDropdownModalOptions = undefined as any;
  selected: any;

  get list() {
    return this.options.list || [];
  }
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private appDialog: AppDialogService,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    this.options = this.dialogConfig.data;
    this.selected = this.options.selected;
  }
  async loadData() {
  }
  close(selected = false) {
    const result: any = {
      selected,
      result: selected ? this.selected : undefined,
    };
    this.dialogRef.close(result);
  }

  getDisplayName(row: any) {
    try {
      return this.options.optionLabel ? row[this.options.optionLabel] : row;
    } catch (e) {
    }
    return "";
  }

  getValue(row: any) {
    try {
      return this.options.optionValue ? row[this.options.optionValue] : row;
    } catch (e) {
    }
    return "";
  }
  getImageIcon(row: any) {
    try {
      return this.options.optionIcon ? row[this.options.optionIcon] : row;
    } catch (e) {
    }
    return "";
  }
  isSelectedRow(row: any) {
    try {
      return this.selected ===
        (this.options.optionValue ? row[this.options.optionValue] : row);
    } catch (e) {}
    return this.selected == row;
  }

  selectItem(row: any, save = false) {

    const value = this.getValue(row);
    this.selected = value;
    if (save) {
      this.close(true);
    }
  }
}

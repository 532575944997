<div *ngIf="options" class="f-col f-fixed">
    <div class="f-row">
        <div class="f-col f-flex">
            <div class="f-align-center f-row">
                <div class="slide-in f-col confirm-icon f-fixed">
                    <img src="/assets/icons/warning.png" alt="" srcset="">
                </div>
                <div class="slide-in f-col confirm-msg f-flex">
                    {{options.summary}}
                </div>
            </div>

        </div>

    </div>
    <div class="f-row"></div>
    <div style="height: 30px;" class="f-row">
        <div class="f-col f-flex"></div>
        <div class="f-col slide-up f-fixed">
            <p-button (click)="close()" [outlined]="true" size="small" [label]="options.cancelButton"
                severity="secondary"></p-button>
        </div>
        <div class="f-col slide-up f-fixed">
            <p-button class="btn-accept" (click)="accept()" [outlined]="true" size="small" [label]="options.confirmButton"
                severity="primary"></p-button>
        </div>
    </div>
</div>
export default [{
  v: 0.10,
  b: 35,
  d: "04-11-2024",
  msg: [
    "Added new workflow node for AI Based Text classifications!",
  ],
}, {
  v: 0.9,
  b: 33,
  d: "03-11-2024",
  msg: [
    "Improved UX for using function / formula!",
    "Added option to easily add any inbuilt functions as a new node!",
  ],
}, {
  v: 0.9,
  b: 30,
  d: "07-10-2024",
  msg: [
    "Fixed slowness issue while using builder!",
  ],
}, {
  v: 0.8,
  b: 29,
  d: "30-09-2024",
  msg: [
    "Added download previous version of flow from the logs!",
    "Added option to stop running flow from logs!",
    "Added user logs for more debugging!",
  ],
}, {
  v: 0.7,
  b: 28,
  d: "27-09-2024",
  msg: [
    "Added support for while loop!",
  ],
}, {
  v: 0.7,
  b: 27,
  d: "26-09-2024",
  msg: [
    "Support added for using dynamic variable name while reading variable value!",
    "Fixed issue where the flow has been broken while reusing saved flow nodes!",
  ],
}, {
  v: 0.7,
  b: 26,
  d: "25-09-2024",
  msg: [
    "Example request and response data support added for API and needed node types in flow!",
  ],
}, {
  v: 0.7,
  b: 25,
  d: "22-09-2024",
  msg: [
    "Multiline encoding/decoding support added for all expression inputs!",
  ],
}, {
  v: 0.7,
  b: 22,
  d: "20-09-2024",
  msg: [
    "Added zoom options in builder page",
  ],
}, {
  v: 0.7,
  b: 21,
  d: "26-08-2024",
  msg: [
    "Added support for file processing (only api mode): Should be able to upload and download files using flows and apis used in flows",
  ],
}, {
  v: 0.6,
  b: 20,
  d: "20-07-2024",
  msg: [
    "Introduced dynamic schema management: Users can now create, update, and delete custom schemas with associated data via a new intuitive interface.",
  ],
}, {
  v: 0.5,
  b: 19,
  d: "12-07-2024",
  msg: [
    "Any custom scripts or apis can be saved and reused.",
  ],
}, {
  v: 0.4,
  b: 17,
  d: "06-07-2024",
  msg: [
    "Added options to Save & reuse forms, scripts and APIs.",
  ],
}, {
  v: 0.4,
  b: 16,
  d: "06-07-2024",
  msg: [
    "Added support swagger upload and template creations from swagger.",
  ],
}, {
  v: 0.3,
  b: 14,
  d: "25-06-2024",
  msg: [
    "Added support for path parameters in API node while using swagger docs.",
  ],
}, {
  v: 0.3,
  b: 11,
  d: "21-06-2024",
  msg: [
    "Added new UI for Applications.",
    "Added Open API Document Import & Export options for Applications",
    "Fixed bug with switch node where 'Run all matching' options selected.",
  ],
}, {
  v: 0.3,
  b: 10,
  d: "16-06-2024",
  msg: [
    "Added new authorisation group modal to easily switch between accounts in all API and Email nodes.",
    "Small UI components improvments.",
  ],
}, {
  v: 0.3,
  b: 9,
  d: "16-06-2024",
  msg: [
    "Added expand collapse option in flows builder for switch and loop nodes!",
    "Added new simple UI to control flow configurations and constraints!",
    "Added Copy functionality for all nodes!",
    "Added Error handling for HTTP nodes!",
    "Fixed bug where the loop node with multiple childs failed to load again properly.",
  ],
}, {
  v: 0.2,
  b: 8,
  d: "15-06-2024",
  msg: [
    "Added new flow node to execute javascript during flow run time.",
    "Fixed bug where failed constraints also triggered the flow run.",
    "Fixed bug where disabled nodes also were running.",
    "Fixed bug where failed to return proper node level errors during run time.",
  ],
}, {
  v: 0.1,
  b: 1,
  d: "16-06-2024",
  msg: [
    "Implemented HTTP node with Endpoint selection options!",
  ],
}] as Array<{
  d: string;
  v: number;
  b: number;
  msg: Array<string>;
}>;

import { Injectable } from "@angular/core";
import { ToasterDialogOptions } from "src/app/core/components/app-dialog/app-dialog.service";
import { GUID } from "src/app/modules/builder/flow-model";
import { parseExpression } from "src/app/modules/builder/workflow-expression/expression-parser";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}
}
export const isValidBase64 = (str: string) => {
  try {
    // Base64 strings are generally divisible by 4.
    if (!str || str.length % 4 !== 0) {
      return false;
    }

    // This regex checks for valid Base64 characters and padding.
    const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;

    // Check if the string matches the regex
    if (!base64Regex.test(str)) {
      return false;
    }

    // Try decoding the string to see if it results in valid data
    const decodedStr = atob(str);

    // Optional: Further checks on the decoded data can be added here.
    return true;
  } catch (error) {
    return false;
  }
};
export const pageNotFound = () => {
  document.write("404 Page not found");
};

export const appLoaded = () => {
  try {
    const loader = document.querySelector(".app-loading.active");
    if (!loader) {
      return;
    }

    loader.classList.remove("active");
  } catch (e) {}
};
export const pageLoaded = appLoaded;

export const sleep = (ms = 1000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
};

export const copyTextToClipboard = (data: any) => {
  let text = data;
  if (typeof data === "object") {
    text = JSON.stringify(data);
  }
  // Create a textarea element
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.setAttribute("readonly", "");
  textarea.style.position = "absolute";
  textarea.style.left = "-9999px"; // Move the textarea offscreen
  document.body.appendChild(textarea);

  // Select the text and copy it to the clipboard
  textarea.select();
  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    // console.log("Copying text command was " + msg);
  } catch (err) {
    console.error("Unable to copy text to clipboard.");
  }

  // Remove the temporary textarea
  document.body.removeChild(textarea);
};

export const toTitleCase = (input: string) => {
  try {
    const words = input.split(" ");
    const titleCaseWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
      return "";
    });
    return titleCaseWords.join(" ");
  } catch (error) {
    return input;
  }
};
export const generateUUIDv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === "x" ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const isValidUUIDV4 = (uuid: GUID) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

export const randomNumber = (min: number, max: number) => {
  if (min >= max) {
    return min;
  }
  return Math.round(Math.random() * (max - min) + min);
};

export const populatePopupPosition = (
  sourceEl: HTMLElement,
  popupEl: HTMLElement,
): DOMRect => {
  const avoidCornerCollision = (domRect: DOMRect) => {
    let [x1, y1, w, h] = [domRect.x, domRect.y, domRect.width, domRect.height];
    x1 = Math.floor(Math.max(x1, 0));
    y1 = Math.floor(Math.max(y1, 0));
    [x2, y2] = [x1 + w, y1 + h];
    x2 = Math.ceil(Math.min(x2, window.innerWidth));
    y2 = Math.ceil(Math.min(y2, window.innerHeight));
    [x1, y1] = [x2 - w, y2 - h];
    return new DOMRect(x1, y1, w, h);
  };
  const sourceRect = sourceEl.getClientRects()[0];
  let [x1, x2, y1, y2] = [
    sourceRect.x,
    sourceRect.x + sourceRect.width,
    sourceRect.y,
    sourceRect.y + sourceRect.height,
  ];
  const popupRect = popupEl.getClientRects()[0];
  let [w, h] = [popupRect.width, popupRect.height];
  const possiblePositions: Array<DOMRect> = [
    new DOMRect(x2, y1, w, h), // Right Bottom
    new DOMRect(x2, y2 - h, w, h), // Right Top
    new DOMRect(x1, y2, w, h), //BOttom Left
    new DOMRect(x2 - w, y2, w, h), // Bottom Right
    new DOMRect(x1 - w, y2 - h, w, h), // Left top
    new DOMRect(x1 - w, y1, w, h), // left bottom
    new DOMRect(x2 - w, y1 - h, w, h), // top left
    new DOMRect(x1, y1 - h, w, h), // top right
  ].map(avoidCornerCollision);

  let availableRect: DOMRect | undefined;
  availableRect = possiblePositions.find((pos) =>
    !isRectColliding(sourceRect, pos)
  );
  if (availableRect) {
    return availableRect;
  }
  return avoidCornerCollision(possiblePositions[0]);
};

export const isRectInsideWindow = (rect: DOMRect) => {
  return Boolean(
    rect.x >= 0 && rect.y >= 0 && rect.x + rect.width <= window.innerWidth &&
      rect.y + rect.height <= window.innerHeight,
  );
};

export const isRectColliding = (rect1: DOMRect, rect2: DOMRect) => {
  const isPointInsideRect = (rect: DOMRect, x: number, y: number) => {
    return x >= rect.x && x <= rect.x + rect.width && y >= rect.y &&
      y <= rect.y + rect.height;
  };
  const [x1, x2, y1, y2] = [
    rect1.x,
    rect1.x + rect1.width,
    rect1.y,
    rect1.y + rect1.height,
  ];
  return Boolean([
    [x1, y1],
    [x1, y2],
    [x2, y1],
    [x2, y2],
  ].find((x) => isPointInsideRect(rect2, x[0], x[1])));
};

export const createCurvedLine = (
  element1Rect: DOMRect,
  element2Rect: DOMRect,
) => {
  const startX = element1Rect.left + element1Rect.width / 2;
  const startY = element1Rect.top + element1Rect.height;
  const endX = element2Rect.left + element2Rect.width / 2;
  const endY = element2Rect.top;

  const controlX = (startX + endX) / 2;
  const controlY = startY - 50;

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", "100%");
  svg.setAttribute("height", "100%");
  svg.setAttribute("position", "absolute");

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute(
    "d",
    `M ${startX} ${startY} Q ${controlX} ${controlY} ${endX} ${endY}`,
  );
  path.setAttribute("stroke", "black");
  path.setAttribute("stroke-width", "2");
  path.setAttribute("fill", "none");

  svg.appendChild(path);

  return svg;
};

export const getNewNameFromList = (
  preferedName: string,
  list: Array<string>,
) => {
  const itemName = preferedName;
  let isExists = false;
  let itemCount = 0;
  do {
    itemCount++;
    isExists = list.includes(`${itemName} ${itemCount}`);
  } while (isExists);
  return `${itemName} ${itemCount}`;
};

export const groupBy = <T>(array: T[], key: keyof T): Array<{
  key: string;
  list: Array<any>;
}> => {
  const res = array.reduce((acc: any, obj) => {
    const keyValue = obj[key];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(obj);
    return acc;
  }, {} as { [key: string]: T[] });
  return Object.keys(res).map((x) => {
    return {
      key: x,
      list: res[x],
    };
  });
};

export const getFileSizeFromBase64 = (base64String: string) => {
  // Base64 string length
  const base64Length = base64String.length;

  // Size in bytes calculation
  const sizeInBytes = (base64Length * 3 / 4) -
    (base64String.indexOf("=") > 0
      ? (base64Length - base64String.indexOf("="))
      : 0);

  // Convert bytes to human-readable format
  return formatBytes(sizeInBytes);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatDuration = (ms: number, round = false) => {
  const units = [
    { label: "Y", value: 1000 * 60 * 60 * 24 * 365 }, // Year
    { label: "M", value: 1000 * 60 * 60 * 24 * 30 }, // Month (approximated as 30 days)
    { label: "W", value: 1000 * 60 * 60 * 24 * 7 }, // Week
    { label: "D", value: 1000 * 60 * 60 * 24 }, // Day
    { label: "H", value: 1000 * 60 * 60 }, // Hour
    { label: "m", value: 1000 * 60 }, // Minute
    { label: "s", value: 1000 }, // Second
    { label: "ms", value: 1 }, // Millisecond
  ];

  let remainingMs = ms;
  let result = [];

  for (let { label, value } of units) {
    if (remainingMs >= value) {
      const unitAmount = Math.floor(remainingMs / value);
      remainingMs %= value;
      result.push(`${unitAmount}${label}`);
    }
  }

  return round && result.length ? result[0] : result.join(" ");
};

export const formatDurationText = (ms: number, round = false) => {
  const units = [
    { label: "Year", value: 1000 * 60 * 60 * 24 * 365 }, // Year
    { label: "Month", value: 1000 * 60 * 60 * 24 * 30 }, // Month (approximated as 30 days)
    { label: "Week", value: 1000 * 60 * 60 * 24 * 7 }, // Week
    { label: "Day", value: 1000 * 60 * 60 * 24 }, // Day
    { label: "Hour", value: 1000 * 60 * 60 }, // Hour
    { label: "Minute", value: 1000 * 60 }, // Minute
    { label: "Second", value: 1000 }, // Second
    { label: "Millisecond", value: 1 }, // Millisecond
  ];

  let remainingMs = ms;
  let result = [];

  for (let { label, value } of units) {
    if (remainingMs >= value) {
      const unitAmount = Math.floor(remainingMs / value);
      remainingMs %= value;
      result.push(`${unitAmount}${label}`);
    }
  }

  return round && result.length ? result[0] : result.join(" ");
};

export const getFriendlyDateName = (date: Date) => {
  const startOfWeek = (dt: Date) => {
    const day = dt.getDay(); // Sunday - Saturday : 0 - 6
    const diff = dt.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(dt.setDate(diff));
  };

  const now = new Date();
  const inputDate = new Date(date);

  // Normalize dates to midnight for accurate comparison
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const inputDay = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate(),
  );

  // Calculate differences in days and months
  const dayDifference = Math.floor(
    (today.getTime() - inputDay.getTime()) / (1000 * 60 * 60 * 24),
  );
  const monthDifference = now.getMonth() - inputDate.getMonth() +
    (12 * (now.getFullYear() - inputDate.getFullYear()));

  // Determine friendly name
  if (dayDifference === 0) {
    return "Today";
  } else if (dayDifference === 1) {
    return "Yesterday";
  } else if (dayDifference < 7 && inputDate >= startOfWeek(now)) {
    return "This week";
  } else if (
    inputDate.getMonth() === now.getMonth() &&
    inputDate.getFullYear() === now.getFullYear()
  ) {
    return "This month";
  } else if (monthDifference === 1) {
    return "Last month";
  } else {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${monthNames[inputDate.getMonth()]} ${inputDate.getFullYear()}`;
  }
};
export const debugComponent = (name: string, component: any) => {
  if (environment.dev) {
    (window as any)[name] = component;
  }
};

export const formatName = (input: string, separator = ' '): string => {
  // Replace underscores with spaces
  let formatted = input.replace(/_/g, " ");

  // Insert spaces before capital letters followed by lowercase letters or numbers
  formatted = formatted.replace(/([a-z])([A-Z])/g, "$1 $2");
  formatted = formatted.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

  // Insert spaces before numbers
  formatted = formatted.replace(/([a-zA-Z])(\d)/g, "$1 $2");

  // Convert to lower case for all words except the first one
  formatted = formatted.split(" ")
    .map((word, index) => index === 0 ? word : word.toLowerCase())
    .join(separator);

  return formatted;
};

export const formatNameAsTitleCase = (
  input: string,
  capitalizeAllFirstLetter = false,
): string => {
  input = input?.trim();
  if (!input) {
    return "";
  }

  // Replace underscores with spaces
  let formatted = input.replace(/_/g, " ");

  // Insert spaces before capital letters followed by lowercase letters or numbers
  formatted = formatted.replace(/([a-z])([A-Z])/g, "$1 $2");
  formatted = formatted.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

  // Insert spaces before numbers
  formatted = formatted.replace(/([a-zA-Z])(\d)/g, "$1 $2");
  formatted = formatted.replace(/(\d)([a-zA-Z])/g, "$1 $2");

  // Convert to lower case for all words except the first one
  formatted = formatted.split(" ")
    .map((word, index) =>
      (index === 0 || capitalizeAllFirstLetter)
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word.toLowerCase()
    )
    .join(" ");

  return formatted;
};

export const updateArrayList = (
  source: Array<any>,
  data: Array<any>,
  uniqueKey: string,
) => {
  const sourceIndex: Array<any> = source.map((x) => x[uniqueKey]);
  const dataIndex: Array<any> = data.map((x) => x[uniqueKey]);

  // delete source data which is not existing in new data list
  for (let i = source.length - 1; i <= 0; i--) {
    if (!dataIndex.includes(sourceIndex[i])) {
      source.splice(i, 1);
    }
  }

  // add new data to source when it is not already exisitng in source data
  data.forEach((row, i) => {
    if (!sourceIndex.includes(dataIndex[i])) {
      source.push(row);
    }
  });
};
export const base64ToBlob = (base64: string, contentType: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export const downloadFileFromBlob = (
  fileName: string,
  contentType: string,
  base64Content: string,
) => {
  // Convert Base64 to Blob
  const blob = base64ToBlob(base64Content, contentType);

  // Create a link element, set its href to the Blob URL, and download the file
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;

  // Append the link to the body, click it, and remove it from the document
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Release the object URL after the download
  URL.revokeObjectURL(url);
};

export const downloadFile = async (jsonString: string, fileName: string) => {
  try {
    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create an anchor element and set the download attribute
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = fileName;

    // Append the anchor to the body
    document.body.appendChild(a);

    // Trigger the download by simulating a click
    a.click();

    // Clean up by removing the anchor element and revoking the object URL
    document.body.removeChild(a);
    URL.revokeObjectURL(a.href);
    return true;
  } catch (e) {}
  return false;
};

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);

    return true;
  } catch (error) {
    return false;
  }
};

export const generateToasterMsgFromApiRes = (
  resp: { success: boolean; message?: string; error?: any },
): ToasterDialogOptions => {
  return {
    severity: resp.success ? "success" : "error",
    summary: resp.success ? "Success!" : "Oops!",
    detail: resp.message || "",
  };
};

export const cloneObject = (data: any) => {
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (error) {
    return data;
  }
};

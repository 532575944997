import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-empty',
    templateUrl: './app-empty.component.html',
    styleUrls: ['./app-empty.component.scss']
})
export class AppEmptyComponent {
    constructor() {

    }

    @Input() icon = '/assets/icons/empty.gif';

    @Input() message = 'No results found!';
}
import { NgModule } from "@angular/core";
import { AppConfirmComponent } from "./app-confirm/app-confirm.component";
import { AppDialogService } from "./app-dialog.service";
import { DialogService } from "primeng/dynamicdialog";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { RenamerModal } from "./renamer/renamer.modal";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { AppDropdownModalComponent } from "./app-dropdown-modal/app-dropdown-modal.component";
import { AppEmptyModule } from "../app-empty/app-empty.module";
import { AppToasterComponent } from "./app-toaster/app-toaster.component";
import { AppToasterService } from "./app-toaster/app-toaster.service";
import { InputTextareaModule } from "primeng/inputtextarea";

@NgModule({
    declarations:[
        AppConfirmComponent,
        RenamerModal,
        AppDropdownModalComponent,
        AppToasterComponent
    ],
    providers:[
        AppDialogService,
        DialogService,
        MessageService
    ],
    imports: [
        AppEmptyModule,
        CommonModule,
        ToastModule,
        FormsModule,
        ButtonModule,
        InputTextModule,
        InputTextareaModule
    ],
    exports: [
        AppToasterComponent
    ],
})
export class AppDialogModule {}
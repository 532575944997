import { AfterViewInit, Component, OnInit } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { WhatsNewService } from "./whats-new/whats-new.service";
import { AppFormService } from "./modules/components/app-form/app-form.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit {

  constructor(
    private whatsNew: WhatsNewService
  ) { }

  ngAfterViewInit(): void {
    console.info('App started');
    this.whatsNew.processWindow();
  }

  openWhatsNew() { }
}
<div *ngIf="options" class="f-col f-flex">
    <div class="f-row sub-title f-fixed">
        {{options.summary}}
    </div>
    <div class="f-row box no-select f-flex">
        <app-empty [message]="options.emptyMessage" *ngIf="!list.length"></app-empty>
        <div *ngIf="list.length" class="f-col f-scroll f-flex item-list">
            <div (click)="selectItem(row)" (dblclick)="selectItem(row, true)" [class.selected]="isSelectedRow(row)"
                *ngFor="let row of list" class="list-item-row f-row">
                <div *ngIf="options.optionIcon" class="f-col f-fixed">
                    <img class="action-icon" [src]="getImageIcon(row)">
                </div>
                <div class="no-select f-col f-flex list-item-name ellipsis">
                    {{getDisplayName(row)}}
                </div>
            </div>
        </div>
    </div>
    <div class="f-row f-fixed" *ngIf="!list.length"></div>
    <div class="f-row f-fixed">
        <div class="f-col f-flex"></div>
        <div class="f-col f-fixed">
            <p-button (click)="close()" [label]="options.cancelButtonLabel || 'Cancel'" severity="secondary"
                [outlined]="true" size="small"></p-button>
        </div>
        <div *ngIf="list.length" class="f-col f-fixed">
            <p-button [disabled]="options.required && !selected" (click)="close(true)"
                [label]="options.selectButtonLabel || 'Select'" [outlined]="true" size="small"></p-button>
        </div>
        <div class="f-col f-fixed">
        </div>
    </div>
</div>
import { Component, Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { catchError, Observable, Subscription, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "any",
})
export class ApiService {
  private apiURL = environment.baseUrl;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    params: new HttpParams(),
  };
  constructor(
    private http: HttpClient,
    private titleService: Title,
  ) {}

  setTitle(text: string) {
    this.titleService.setTitle(`${text} | Intrina`);
  }
  get(pathUrl: string, params?: any): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(
      this.apiURL + pathUrl,
      this.httpOptions,
    )
      .pipe(catchError(this.handleError));
  }
  postAPI(
    pathUrl: string,
    body: any,
    queryParams: any = {},
    headers: any = {},
  ): Observable<any> {
    let params = new HttpParams();
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        params = params.append(key, queryParams[key]);
      }
    }

    let httpHeaders = new HttpHeaders();
    for (const key in headers) {
      if (headers.hasOwnProperty(key)) {
        httpHeaders = httpHeaders.append(key, headers[key]);
      }
    }

    const options = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.post(
      `${this.apiURL}${pathUrl}`,
      JSON.stringify(body),
      options,
    ).pipe(
      catchError(this.handleError),
    );
  }

  post(
    pathUrl: string,
    params: any,
    ContentType: string = "application/json",
  ): Observable<any> {
    if (ContentType == "application/x-www-form-urlencoded") {
      params = new URLSearchParams(params);
    } else if (ContentType == "multipart/form-data") {
      params = params;
    } else if (ContentType == "application/json") {
      params = JSON.stringify(params);
    }
    return this.http.post(
      this.apiURL + pathUrl,
      params,
      ContentType == "application/json" ? this.httpOptions : {},
    ).pipe(catchError(this.handleError));
  }

  put(pathUrl: string, params: any): Observable<any> {
    return this.http.put(
      this.apiURL + pathUrl,
      JSON.stringify(params),
      this.httpOptions,
    ).pipe(catchError(this.handleError));
  }

  delete(pathUrl: string, params: any): Observable<any> {
    if (params) {
      this.httpOptions.params = params;
    }
    return this.http.delete(
      this.apiURL + pathUrl,
      this.httpOptions,
    ).pipe(catchError(this.handleError));
  }

  custom(
    url: string,
    method: string,
    body: any,
    headers: any,
    query: any,
  ): Observable<any> {
    method = method.toLowerCase();
    let options = {
      ...this.httpOptions,
    };
    if (headers) {
      options.headers = headers;
      options.params = query;
    }
    
    return this.http.post(
      this.apiURL + url,
      body,
      options,
    ).pipe(catchError(this.handleError));
  }

  // Error handling
  private handleError(error: any) {
    let errorMessage = "";
    if (error.error) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  setStorage(key: string, value: any) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (e) {}
    return false;
  }

  getStorage(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key) as any);
    } catch (e) {}
    return null;
  }

  unsubscribeSubscriptions(s: Array<Subscription>) {
    if (Array.isArray(s)) {
      s.forEach((x) => {
        try {
          x.unsubscribe();
        } catch (e) {}
      });
    }
  }
}

import { Injectable } from "@angular/core";
import {
  AppDialogModalOption,
  AppDialogModalResponse,
} from "./app-dialog.model";
import { DialogService, DynamicDialogConfig } from "primeng/dynamicdialog";
import { AppConfirmComponent } from "./app-confirm/app-confirm.component";
import { RenamerModal } from "./renamer/renamer.modal";
import {
  RenamerModalOptions,
  RenamerModalResult,
} from "./renamer/renamer.model";
import { MessageService } from "primeng/api";
import { AppDropdownModalOptions } from "./app-dropdown-modal/app-dropdown-modal.model";
import { AppDropdownComponent } from "../app-dropdown/app-dropdown.component";
import { AppDropdownModalComponent } from "./app-dropdown-modal/app-dropdown-modal.component";
import { AppToasterService } from "./app-toaster/app-toaster.service";
import { AppToasterModel } from "./app-toaster/app-toaster.model";

@Injectable({
  providedIn: "any",
})
export class AppDialogService {
  constructor(
    private dialogService: DialogService,
    private messageService: MessageService,
    private appToasterService: AppToasterService,
  ) {}
  openModalComponent(componentType: any, config: DynamicDialogConfig) {
    return this.dialogService.open(componentType, config);
  }

  confirm(options: AppDialogModalOption): Promise<AppDialogModalResponse> {
    return new Promise((resolve, reject) => {
      const currentOptions: AppDialogModalOption = {
        header: "Confirm",
        summary: "Are you sure to proceed with this?",
        confirmButton: "Yes, Continue",
        cancelButton: "Cancel",
        dismissable: true,
        closable: true,
        ...options,
      };
      const ref = this.dialogService.open(AppConfirmComponent, {
        modal: true,
        header: currentOptions.header,
        data: {
          options: currentOptions,
        },
        dismissableMask: currentOptions.dismissable,
        closable: currentOptions.closable,

        style: {
          maxWidth: "500px",
        },
        width: "50vw",
      });
      ref.onClose.subscribe((x) => {
        resolve(x || {
          dismissed: false,
          cancelled: false,
          result: false
        });
      }, () => {
        resolve({
          dismissed: false,
          cancelled: false,
          result: false,
        });
      });
    });
  }

  toaster(options: Partial<AppToasterModel>) {
    const defaultOptions: AppToasterModel = {
      summary: "",
      duration: 3000,
      detail: "",
      severity: "info",
      ...options,
    };
    this.appToasterService.toastSubject.next(defaultOptions);
  }

  async renamer(
    options: Partial<RenamerModalOptions> = {},
  ): Promise<RenamerModalResult> {
    const opt: RenamerModalOptions = {
      subject: "",
      textarea: false,
      itemname: "",
      header: "Rename",
      label: "Enter name",
      existingValues: [],
      renameAction: true,
      maxLength: 100,
      isRequired: true,
      isUnique: false,
      mustRename: false,
      value: "",
      deleteAction: false,
      deleteTitle: "",
      deletePrompt: "",
      ...options,
    };
    const ref = this.dialogService.open(RenamerModal, {
      modal: true,
      header: opt.header,
      data: {
        options: opt,
      },
      style: {
        maxWidth: "500px",
      },
      width: "50vw",
    });
    return new Promise((resolve, reject) => {
      ref.onClose.subscribe((data) => {
        resolve(data || {
          deleted: false,
          saved: false,
          value: "",
          touched: false,
        });
      }, () => {
        resolve({
          deleted: false,
          saved: false,
          value: "",
          touched: false,
        });
      });
    });
  }

  async openDropdownModal(options: Partial<AppDropdownModalOptions>): Promise<{
    result: any;
    selected: boolean;
  }> {
    const defaultOptions: AppDropdownModalOptions = {
      list: [],
      header: "",
      summary: "",
      optionIcon: "",
      optionLabel: "",
      selectButtonLabel: 'Select',
      cancelButtonLabel: 'Cancel',
      optionValue: "",
      required: false,
      selected: undefined,
      emptyMessage: `No results found`,
      ...options,
    };
    const ref = this.openModalComponent(AppDropdownModalComponent, {
      data: defaultOptions,
      header: defaultOptions.header,
      closable: true,
    });
    return new Promise((resolve, reject) => {
      ref.onClose.subscribe((x) => {
        resolve(x || { result: undefined, selected: false } as any);
      });
    });
  }
}

export interface ToasterDialogOptions {
  severity: "success" | "info" | "warn" | "error";
  summary?: string;
  detail?: string;
}

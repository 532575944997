import { AfterViewInit, Component, ElementRef, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  AppDialogModalOption,
  AppDialogModalResponse,
} from "../app-dialog.model";

@Component({
  selector: "app-confirm",
  templateUrl: "./app-confirm.component.html",
  styleUrls: ["./app-confirm.component.scss"],
})
export class AppConfirmComponent implements OnInit, AfterViewInit {
  options: AppDialogModalOption = undefined as any;
  result: AppDialogModalResponse = {
    result: false,
    dismissed: false,
    cancelled: false,
  };
  constructor(
    private elementRef: ElementRef,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.options = this.dialogConfig.data?.options as any;
    if (!this.options) {
      return this.close();
    }
  }

  focusAcceptButton() {

  }

  ngAfterViewInit() {
    this.focusAcceptButton();
  }
  close() {
    this.dialogRef.close(this.result);
  }

  accept() {
    this.result.result = true;
    this.close();
  }
}

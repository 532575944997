<div @fadeAnimation class="no-select app-toast f-col severity-{{toast.severity}}" *ngFor="let toast of toasterList">
    <div class="f-row app-toast-header">
        <div class="f-col f-flex">
            <ng-container>
                {{toast.summary}}
            </ng-container>
        </div>
        <div class="f-col f-fixed">
            <i (click)="clearToast(toast)" class="pi clickable pi-times"></i>
        </div>
    </div>
    <div *ngIf="toast.detail" class="f-row app-toast-msg">
        {{toast.detail}}
    </div>
</div>


<div *ngIf="false" class="no-select app-toast app-toast-loading f-col severity-info">
    <div class="f-row">
        <div class="f-col f-fixed">
            <img src="/assets/icon.png">
        </div>
        <div class="f-col f-fixed"></div>
        <div class="f-col f-flex">
            <div class="f-row app-toast-header">
                <div class="f-col f-flex">
                    <ng-container>
                        LOADING
                    </ng-container>
                </div>

            </div>
            <div class="f-row app-toast-msg">
                this is sample mesage content for loading
            </div>
        </div>
    </div>
</div>
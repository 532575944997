<div class="box f-row f-flex">

    <div class="f-col slide-right f-fixed">
        <img class="whatsnew-icon" src="/assets/icons/whats_new.png">
    </div>
    <div class="f-col f-flex content-list">

        <div *ngFor="let vRow of list" class="f-row slide-down f-fixed">
            <div class="vbox f-col f-flex">
                <div class="f-row  slide-down f-align-center">
                    <div class="f-col f-fixed">

                    </div>
                    <div class="f-col f-fixed">
                        <h4>
                            <b> V {{vRow.v}}.{{vRow.b}}</b>
                        </h4>

                    </div>
                    <div class="f-col f-fixed">
                        <small class="label">{{vRow.d}}</small>
                    </div>
                </div>
                <div class="f-row slide-down">
                    <div class="f-col f-flex">
                        <ul>
                            <li *ngFor="let msg of vRow.msg">
                                {{msg}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppToasterModel } from "./app-toaster.model";

@Injectable({
    providedIn: "root",
})
export class AppToasterService {
    toastSubject = new Subject<AppToasterModel>();
    onToast$ = this.toastSubject.asObservable();
    constructor() {
    
    }
}

import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppToasterModel } from "./app-toaster.model";
import { AppToasterService } from "./app-toaster.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-toaster",
    templateUrl: "./app-toaster.component.html",
    styleUrls: ["./app-toaster.component.scss"],
    animations: [
        trigger("fadeAnimation", [
            // Transition for entering the element
            transition(":enter", [
                style({
                    opacity: 0,
                    maxHeight: "0px",
                    transform: `translate(0px, -50px)`,
                }), // Starting state
                animate(
                    "100ms ease-in",
                    style({
                        opacity: 1,
                        maxHeight: "300px",
                        transform: `translate(0px, 0px)`,
                    }),
                ), // Final state
            ]),
            // Transition for leaving the element
            transition(":leave", [
                animate(
                    "300ms ease-out",
                    style({
                        opacity: 0,
                        transform: `translate(0px, 50px)`,
                    }),
                ), // Final state
            ]),
        ]),
    ],
})
export class AppToasterComponent implements OnInit, OnDestroy {
    toasterList: Array<AppToasterModel> = [];
    subscriptions: Array<Subscription> = [];
    constructor(
        private toasterService: AppToasterService,
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.toasterService.onToast$.subscribe((toast) => {
                this.addToast(toast);
            }),
        );
    }

    clearToast(toast: AppToasterModel) {
        this.toasterList.splice(this.toasterList.indexOf(toast), 1);
    }

    addToast(toast: AppToasterModel) {
        if(!toast.summary && toast.detail) {
            toast.summary = toast.detail;
            toast.detail = '';
        }
        this.toasterList.splice(0, 0, toast);
        if (toast.duration) {
            setTimeout(() => {
                this.clearToast(toast);
            }, toast.duration);
        }
    }
}

import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { RenamerModalOptions, RenamerModalResult } from "./renamer.model";
import { UiService } from "src/app/core/services/ui.service";
import { AppDialogService } from "../app-dialog.service";

@Component({
    selector: 'app-renamer',
    templateUrl: './renamer.modal.html',
    styleUrls: ['./renamer.modal.scss']
})
export class RenamerModal implements OnInit {
    options: RenamerModalOptions = undefined as any;

    result: RenamerModalResult = {
        value: '',
        deleted: false,
        saved: false,
        touched: false
    }
    constructor(
        private appDialogService: AppDialogService,
        private dialogConfig: DynamicDialogConfig,
        private ref: DynamicDialogRef
    ) { }

    get errorMsg() {
        const { touched, value } = this.result;
        const { isRequired, isUnique, existingValues } = this.options;
        if (!touched && !value) {
            return '';
        }
        if (isRequired && !value) {
            return 'Please provide a value';
        }
        if (isUnique && existingValues.find(x => x.toLowerCase() === value.toLowerCase())) {
            return `${value} already exists!`;
        }
        return '';
    }
    ngOnInit(): void {
        const data = this.dialogConfig.data;
        this.options = data.options;
        this.result.value = this.options.value;
    }

    close() {
        this.result.saved = false;
        this.ref.close(this.result);
    }

    save() {

        if (this.options.isRequired && !this.result.value || this.errorMsg != '') {
            return false;
        }
        this.result.saved = true;
        this.ref.close(this.result);
        return true;
    }

    async del() {
        const resp = await this.appDialogService.confirm({
            summary: this.options.deletePrompt,
            header: this.options.deleteTitle || 'Confirm delete?'
        });
        
        if (resp.result) {
            this.result.deleted = true;
            this.ref.close(this.result);
        }
    }
}
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";


const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
    canActivate: [AuthGuard],
  },
  {
    path: "flows",
    loadChildren: () =>
      import("./modules/flows/flows.module").then((m) => m.FlowsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "builder",
    loadChildren: () =>
      import("./modules/builder/builder.module").then((m) =>
        m.WorkFlowBuilderModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "connectors-old",
    loadChildren: () =>
      import("./modules/connectors/connectors.module").then((m) =>
        m.ConnectorsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "applications",
    loadChildren: () =>
      import("./modules/applications/applications.module").then((m) =>
        m.ApplicationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "schemas",
    loadChildren: () =>
      import("./modules/schemas/schemas.module").then((m) => m.SchemasModule),
    canActivate: [AuthGuard],
  },
  {
    path: "schema",
    loadChildren: () =>
      import("./modules/schema/schema.module").then((m) => m.SchemaModule),
    canActivate: [AuthGuard],
  },
  /*  {
    path: "action-group",
    loadChildren: () =>
      import("./modules/action-group/action-group.module").then((m) =>
        m.ActionGroupModule
      ),
    canActivate: [AuthGuard],
  }, */
  {
    path: "connections",
    loadChildren: () =>
      import("./modules/connection/connection.module").then((m) =>
        m.ConnectionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "logs",
    loadChildren: () =>
      import("./modules/logs/logs.module").then((m) => m.LogsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "vars",
    loadChildren: () =>
      import("./modules/vars/vars.module").then((m) => m.VarsModule),
    canActivate: [AuthGuard],
  },
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "connectors", redirectTo: "applications", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, finalize, of, tap, throwError } from 'rxjs';
import { generateUUIDv4 } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'any'
})
export class interceptor implements HttpInterceptor {
  private httpCnt: number; // TO CHECK ANY HTTP CALL IS PENDING

  constructor(
    private authService: AuthService
  ) {
    this.httpCnt = 0;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.httpCnt += 1;

    if (environment.mockAPI) {
      return of(new HttpResponse(mockRequest(request)));
    }

    return next.handle(request).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.status == 401) {
              console.error("Unauthorised");
              this.authService.logout();
            }
          }

          return event;
        },
        error: (error) => {
          if (error.status == 401) {
            console.error("Unauthorised");
            this.authService.logout();
          }

          return throwError(() => error)
        }
      }),
      finalize(() => {
        this.httpCnt -= 1;
        if (this.httpCnt === 0) {
          
        }
      }));
  }
}


const mockRequest = (request: HttpRequest<any>): {
  body?: any;
  headers?: HttpHeaders | undefined;
  status?: number | undefined;
  statusText?: string | undefined;
} => {

  const url = request.url.split("/");
  
  (window as any).lastRequest = request;
  const [schema, action] = [url[2], url[3]];
  let data: any;
  let success = false;
  let message = '';
  let list = [];
  let body: any = {};
  let count: any = undefined;

  try {
    body = JSON.parse(request.body);
  } catch (e) { }
  let id = body?.id || generateUUIDv4();
  let name = url[4] || body?.name || '';
  let param = request.method === 'POST' ? {} : request.params.keys().reduce((obj, newkey) => {
    obj[newkey] = request.params.get(newkey);
    return obj;
  }, {} as any);
  let rowIndex = -1;

  switch (action) {
    case 'list':
      const start = param.start || 0;
      const search = `${param.search || ''}`.trim().toLowerCase();
      const limit = start + (param.limit || 100);
      list = ((getStorage(schema) || []) as Array<any>);
      list = search ? list.filter((row) => {
        const props = Object.keys(row).filter(x => x !== 'id');
        for (let i = 0; i < props.length; i++) {
          const val = row[props[i]];
          if (typeof val === 'number' || typeof val === 'string') {
            if (`${val}`.toLowerCase().includes(search)) {
              return true;
            }
          }
        }
        return false;
      }) : list;
      count = list.length;
      data = list.slice(start, limit);
      success = true;
      message = `${schema} list has been loaded!`;
      break;
    case 'delete':
      list = (getStorage(schema) || []) as Array<any>;
      rowIndex = list.findIndex(x => x.id === id);

      if (rowIndex > -1) {
        list.splice(rowIndex, 1);
        success = true;
      } else {
        success = false;
        message = 'Information was already deleted';
      }
      setStorage(schema, list);
      break;
    case 'save':
      list = (getStorage(schema) || []) as Array<any>;
      rowIndex = list.findIndex(x => x.id === id);
      const nameExists = list.findIndex(x => x.name && x.name === body.name && x.id != body.id);
      if (nameExists > -1) {
        success = false;
        message = `${schema} name "${body.name}" already exists!`;
        break;
      } else if (rowIndex > -1) {
        const row = list[rowIndex];
        Object.assign(row, body);

      } else {
        list.push(body);
      }
      success = true;
      message = `${schema} has been saved!`;
      setStorage(schema, list);
      break;
    case 'get':
      list = (getStorage(schema) || []) as Array<any>;
      rowIndex = list.findIndex(x => x.id === id);
      if (rowIndex > -1) {
        success = true;
        data = list[rowIndex];
      } else {
        success = false;
        message = `${schema} name is invalid or not found!`;
      }
      break;
    case 'getByName':
      list = (getStorage(schema) || []) as Array<any>;
      rowIndex = list.findIndex(x => x.name === name);
      if (rowIndex > -1) {
        data = list[rowIndex];
        success = true;
      } else {
        success = false;
        message = `${schema} name is invalid or not found!`;
      }
      break;
    case 'getNewName':
      list = (getStorage(schema) || []) as Array<any>;
      const itemName = 'Untitled ' + `${schema[0] || ''}`.toUpperCase() + schema.substring(1);
      let isExists = false;
      let itemCount = 0;
      do {
        itemCount++;
        isExists = list.find(x => x.name === `${itemName} ${itemCount}`);
      } while (isExists);
      success = true;
      data = {
        name: `${itemName} ${itemCount}`
      };
      break;
  }
  const response = {
    body: {
      success,
      count,
      message,
      data,
      param,
      requestBody: body
    },
    status: 200
  };
  console.info('HTTP Response', { response, url, data, body, id });
  return response;
}

const setStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) { }
  return false;
}

const getStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) as any);
  } catch (e) { }
  return null;
}
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'any'
})
export class AuthService {
  private userLogedIn: boolean;
  constructor() {
    this.userLogedIn = false;
  }

  validateUser() {
    this.userLogedIn = true;
  }

  get isLoggedIn(): boolean {
    this.userLogedIn = true;
    return this.userLogedIn;
  }

  login() {
    const origin = window.location.origin;

    window.location.href = `${origin}${environment.baseUrl}/v1/auth/login`;
  }

  logout() {
    const origin = window.location.origin;

    window.location.href = `${origin}${environment.baseUrl}/v1/auth/logout`;
  }
}
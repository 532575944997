import { Injectable } from '@angular/core';
import { Confirmation, ConfirmationService, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'any'
})
export class UiService {

  constructor(
    private confirmationService: ConfirmationService, private messageService: MessageService
  ) { }

  toastMessage(options: {
    severity: "success" | "info" | "warn" | "error";
    summary?: string;
    detail?: string;
  }) {
    const defaultOptions = {
      detail: "",
      ...options
    };
    this.messageService.add(defaultOptions);
  }

  confirm(params: Partial<Confirmation>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        header: params.header,
        target: params.target as EventTarget,
        message: params.message,
        icon: params.icon || 'pi pi-exclamation-triangle',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }
}

import { NgModule } from "@angular/core";
import { AppEmptyComponent } from "./app-empty.component";

@NgModule({
    
    declarations: [
        AppEmptyComponent
    ],
    exports:[
        AppEmptyComponent
    ]
})
export class AppEmptyModule{}
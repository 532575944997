import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { interceptor } from './interceptors/interceptor';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { UiService } from './services/ui.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { WhatsNewComponent } from '../whats-new/whats-new.component';
import { WhatsNewService } from '../whats-new/whats-new.service';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollDirective } from './directives/infinite-scroll';
import { AppDialogModule } from './components/app-dialog/app-dialog.module';
@NgModule({
    declarations: [
        WhatsNewComponent,
        InfiniteScrollDirective
    ],
    imports: [
        ConfirmDialogModule,
        HttpClientModule,
        AppDialogModule,
        DynamicDialogModule,
        CommonModule,
        FormsModule,
        ToastModule
    ],
    exports: [
        WhatsNewComponent,
        AppDialogModule,
        ConfirmDialogModule,
        ToastModule,
        InfiniteScrollDirective
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS, useClass: interceptor, multi: true
        },
        ApiService,
        AuthService,
        DialogService,
        UiService,
        ConfirmationService,
        MessageService,
        WhatsNewService
    ]
})
export class CoreModule { }

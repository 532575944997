import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import * as whatsnewData from "./whats-new";

@Component({
  selector: "whats-new",
  styleUrls: ["./whats-new.component.scss"],
  templateUrl: "./whats-new.component.html",
})
export class WhatsNewComponent implements OnInit {
  list: Array<{
    v: number;
    d: string;
    b: number;
    msg: Array<string>;
  }> = [];
  constructor(
    private dialogConfig: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    const data = whatsnewData.default;
    const minBuildNo = this.dialogConfig.data.build || 0;
    const filteredData = data.filter((x) => x.b > minBuildNo);
    const list: Array<{
      v: number;
      b: number;
      d: string;

      msg: Array<string>;
    }> = [];
    const indexList: {
      [index: string]: {
        v: number;
        d: string;
        b: number;
        msg: Array<string>;
      };
    } = {};
    filteredData.forEach((x) => {
      const { v, b, msg, d } = x;
      let row = indexList[v];
      if (!indexList[v]) {
        row = {
          v,
          d,
          b,
          msg: [],
        };
        indexList[v] = row;
        list.push(row);
      }

      if (b > row.b) {
        row.b = b;
      }
      row.msg.push(...msg);
    });
    this.list = list;
  }
}

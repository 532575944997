import { Directive, HostListener, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
  selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective {
  @Input() scrollThreshold: number = 100; // Distance from the bottom to trigger loading
  @Output() scrolledToEnd: EventEmitter<void> = new EventEmitter<void>();

  private lastScrollTop: number = 0;

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) <= this.scrollThreshold) {
      if (scrollTop > this.lastScrollTop) { // Check if scrolling down
        this.scrolledToEnd.emit();
      }
    }
    this.lastScrollTop = scrollTop;
  }
}
